
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import { useFormContext, Controller } from 'react-hook-form';
import { memo, useEffect, useState,useRef } from 'react';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { formatter,default_BOL_material } from '../../../../../../constants';
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Icon,
  ListSubheader,
  styled,
  Switch,
  Checkbox
} from '@mui/material';
import ModalCreateUnits from '../../../../unitTempsMaterials/unitTemps/ModalCreateUnits';
import ModalCreateMaterials from '../../../../e-commerce/ModalCreateMaterials';
import store from 'app/store';
import Check_refresh from '../../../../Check_refresh';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import '../../CreateJobBilling.css';
import { connect,useDispatch } from 'react-redux';
import client from 'client';
import ModalSpecialRate from './ModalSpecialRate';
import { showMessage } from 'app/store/fuse/messageSlice';
import { hasPermission } from 'app/main/loadz/utils';
import AgentCommissionRateModal from './AgentCommissionRateModal';
import CommissionMatrixHistoryModal from './CommissionMatrixHistoryModal';
import HistoryIcon from '@mui/icons-material/History';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import ButtonLog from 'app/main/ButtonLog';
import { IMPORT } from 'stylis';

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
  />
))(({ theme }) => ({
  width: 42,
  height: 25,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 4,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#000000' : '#f1c232',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#f1c232',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#000000' : '#000000',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function CreateJobBilling({
  jsonGetById,
  user,
  deletespecial,
  setDeleteSpecial,
  data,
  setJob_id,
  setEditJobBillingOpen,
  commissionMatrix,
  setCommissionMatrix,
  setCommissionMatrixChanged,
  setAutoInvoice,
  autoInvoice,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [createMaterials, setCreateMaterials] = useState(false);
  const [mate, setMate] = useState([]);
  const [contra, setcontra] = useState([]);
  const [ownerOperator, setOwner] = useState([]);
  const [subs, setSubs] = useState([]);
  const [ownerlist, setOwnerlist] = useState([]);
  const [cpuchange, setcpuchange] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [units, setUnits] = useState([]);
  const [createUnits, setCreateUnits] = useState(false);
  const [showCost, setCost] = useState(jsonGetById.length > 0 && jsonGetById?.[0]?.job_billing ? jsonGetById?.[0]?.job_billing?.material_broker  ? true : false : user?.company?.materialbroker ? true : false );
  const [showComission, setComission] = useState(true);
  const [customerHauling, setCustomerHauling] = useState(true);
  const [taxRate, setTax] = useState(jsonGetById.length > 0 && jsonGetById?.[0]?.job_billing ? jsonGetById?.[0]?.job_billing?.material_broker  ? true : false : user?.company?.materialbroker ? true : false );
  const [jobtype, setJobtype] = useState(null);
  const [position, setPositions] = useState(0);
  const [dataSpecial, setDataSpecial] = useState([]);
  const [isEditSpecial, setIsEditSpecial] = useState(false);
  const [isOpenModalSpecial, setIsOpenModalSpecial] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentIdentifi, setCurrentIdentifi] = useState(null);
  const history = useHistory();
  const methods = useFormContext();
  const { control, formState, setValue, getValues, reset } = methods;
  const { errors } = formState;
  const [agentsOptions, setAgentOptions] = useState([])
  const [agentModal, setAgentModal] = useState(false)
  const [agentModalEdit,setAgentModalEdit] = useState(false)
  const [commissionMatrixIndex, setCommissionMatrixIndex] = useState(null)
  const [selectedCommissionMatrix, setSelectedCommissionMatrix] = useState(null)
  const [matrixModal, setMatrixModal] = useState(false)
  const [filteredAgents, setFilteredAgents] = useState([])

  const [inputFields, setInputFields] = useState([
    // {
    //   identifi: uuidv4(),
    //   subcontractor_comp_id: '',
    //   unit_rate: '',
    //   company_id: '',
    //   hourly_rate: 0,
    //   date_created: new Date(),
    // },
  ]);
  const dispatch = useDispatch();

  const _showMessage = (msg, color) => {
    dispatch(
      showMessage({
        message: msg, //text or html
        autoHideDuration: 6000, //ms
        anchorOrigin: {
          vertical: 'top', //top bottom
          horizontal: 'right', //left center right
        },
        variant: color, //success error info warning null
      })
    );
  };

  const notifySubs = async (data) => {
    data.type = 'subs unit cost';
    const token = localStorage.getItem('token');
    await client.post(`/jobs/unhide-subs`, data, { headers: { token: token } });
    await client.post(`/comm/notif`, data);
  };

  const handleChangeInput = (identifi, event) => {
    const newInputFields = inputFields.map((i) => {
      if (identifi === i.identifi) {
        i[event.target.name] = event.target.value;
      }
      
      if(Number(i.unit_rate) > getValues('billperunit').replace(/[^0-9.-]+/g, ''))
      {_showMessage('The rate entered is greater than the Unit Invoice Rate', 'warning');}
      if(Number(i.unit_rate) < getValues('billperunit').replace(/[^0-9.-]+/g, ''))
      {_showMessage('The rate entered is less than the Unit Invoice Rate', 'warning');}

      setInputFields([
        ...inputFields,
        {
          identifi: uuidv4(),
          subcontractor_comp_id: '',
          unit_rate: '',
          company_id: '',
          hourly_rate: 0,
          date_created: new Date(),
        },
      ]);
      setValue('subcontractor', inputFields);
      return i;
    });

    setInputFields(newInputFields);
  };

  const handleAddFields = () => {
    setInputFields([
      ...inputFields,
      {
        identifi: uuidv4(),
        subcontractor_comp_id: '',
        unit_rate: '',
        company_id: '',
        hourly_rate: 0,
        date_created: new Date(),
      },
    ]);
    setPositions(inputFields.length);
    setValue('subcontractor', inputFields);
  };

  const handleOpenModal = () => {
    setIsOpenModalSpecial(true);
    // handleAddFields();
  };

  const handleRemoveFields = async (identifi) => {
    setShowModal(true);
    setCurrentIdentifi(identifi);
  };

  const handleEditFields = async (id) => {
    const values = [...inputFields];
    if (values[0].subcontractor_comp_id == '') {
      return;
    }
    let posicion = -1;

    for (let i = 0; i < values.length; i++) {
      if (values[i].identifi === id) {
        posicion = i;
        break;
      }
    }
    setIsEditSpecial(true);
    setPositions(posicion);
  };

  const getAllMaterials = async () => {
    const id = data ? data.id : store.getState().loadz_auth?.AuthReducer?.user?.user_id;
    let mate = [];
    try {
      const _materials = await client.post(`/materials/all`, { id });
      const jsonMaterials = await _materials.data;
      mate = jsonMaterials.map((e) => {
        return { value: e.mt_id, label: e.name };
      });
      if(jsonGetById[0]?.bol_type == true)
        {
          mate.unshift(default_BOL_material)
          setValue('materials', [default_BOL_material])

        }
      mate.unshift({ value: 0, label: 'Create Materials' });

      

      setMate(mate);
    } catch (error) {
      mate = [{ value: 0, label: 'Create Materials' }];
    }
  };

  const getAllcontra = async () => {
    const compacctnum = data ? data.compacctnum : user?.company?.compacctnum;
    try {
      const subs = await client.post(`/props/get-subcon`, { compacctnum });
      const jsonSubs = await subs.data;
      const subcons = jsonSubs.subcon;
      const opt_subs = subcons.map((e) => {
        return {
          compacctnum: e.compacctnum,
          value: e.user_id,
          label: e.compname,
          type: e.relation,
        }; //might have to remove copaactnum dontknow
      });
      setSubs(opt_subs);
      setcontra(jsonSubs.subcon);
    } catch (error) {}
  };

  const getAllOwner = async () => {
    const compacctnum = data ? data.compacctnum : user?.company?.compacctnum;

    try {
      const subs = await client.post(`/ownerop/get`, { comp_acct: compacctnum });
      const jsonSubs = await subs.data;
      const subcons = jsonSubs.ownerops;
      const opt_owner = subcons.map((e) => {
        return { compacctnum: e.compacctnum, value: e.user_id, label: e.compname, type: e.ownerop }; //might have to remove copaactnum dontknow
      });
      setOwnerlist(opt_owner);
      setOwner(jsonSubs.ownerops);
    } catch (error) {}
  };

  const getAllUnits = async () => {
    const id = data ? data.id : user?.user_id;
    let units = [];
    try {
      const _units = await client.post(`/unitTemps/all`, { id });
      const jsonUnits = await _units.data;
      units = jsonUnits.map((e) => {
        return { value: e.id_units, label: e.unit_name };
      });
      units.unshift({ value: 0, label: 'Create Units' });
      if(jsonGetById[0]?.bol_type == true)
      {
        const unit = units.find(i => i.label.includes('Load'))
        setValue('unit_id', [unit])
      }
      setUnits(units);
    } catch (error) {
      units = [{ value: 0, label: 'Create Units' }];
    }
  };

  useEffect(async () => {
    const result = await Check_refresh();
    if (!result) {
      return history.replace('/login');
    }
    setIsOpenModal(false);
    if (createMaterials) {
      setIsOpenModal(true);
      setValue('materials', []);
    }
  }, [createMaterials]);

  useEffect(async () => {
    !isOpenModal ? setCreateMaterials(false) : null;
  }, [isOpenModal]);

  const handle = (e) => {
    setValue('material_broker', e.target.checked);
    setCost(e.target.checked);
    setTax(true);
    setValue('taxable', true);
    setCustomerHauling(true);
    if (e.target.checked == false) {
      setTax(false);
      setCustomerHauling(false);
      setValue('taxable', false);
      setValue('material_cost_rate', '$0.00');
      setValue('material_invoice_rate', '$0.00');
    }
  };

  const comission = (e) => {
    setValue('commissions', e.target.checked);
    setComission(e.target.checked);
  };

  const handleCustomerHauling = (e) => {
    if (e.target.checked == false) {
      setValue('bill_invoice_rate', '$0.00');
    }

    setValue('customer_hauling', e.target.checked);
    setCustomerHauling(e.target.checked);
  };

  const tax = (e) => {
    if (!showCost) {
      setValue('taxable', false);
      setTax(false);
    } else {
      setValue('taxable', e.target.checked);
      setTax(e.target.checked);
    }
  };

  useEffect(() => {
    setIsOpen(false);
    if (createUnits) {
      setIsOpen(true);
      setValue('unit_id', []);
    }
  }, [createUnits]);

  useEffect(async () => {
    !isOpen ? setCreateUnits(false) : null;
    getAllUnits();
  }, [isOpen]);

  const getAllAgents = async () => {
    client.post('/agents/select-option', {compacctnum : user?.company?.compacctnum})
      .then(async(result) => {
        //agents that already in the job
        const agents_needed = commissionMatrix.filter(i => !i?.deleted)
        //only agents that are not disabled
        //the agents that are in agents_needed tell me which ones I need from result.data
        const temp = await Promise.all(agents_needed.map(i => result.data.find(j => j.value === i.agent_id)))
        //add from result.data the agents that disabled == false and are not in temp
        temp.push(...result.data.filter(i => !i?.disabled && !temp.find(j => j.value === i.value)))
        setAgentOptions(temp)
      }).catch((e) => console.log(e))
  }
  useEffect(async () => {
    getAllcontra();
    getAllOwner();
    getAllMaterials();
    getAllUnits();
    getAllAgents()
    console.log('showCost',showCost, 'taxRate',taxRate);

    
   
    if (jsonGetById.length > 0 && jsonGetById[0].job_billing) {
      const { job_billing } = jsonGetById[0];
      const { job_type, job_info_id } = jsonGetById[0];
      setJobtype(job_type);
      setcpuchange(jsonGetById[0].job_billing.cpu_sub_change);
      data ? setJob_id(job_info_id) : null;

      if (jsonGetById[1]) {
        const { subcontractor } = jsonGetById[1];
        if (subcontractor && subcontractor.length >= 1) {
          const new_subcon = JSON.parse(JSON.stringify(subcontractor));
          setInputFields(new_subcon);
          setValue('subcontractor', new_subcon);
          // setPositions(subcontractor.length)
        }
      }

      const {
        bill_per_unit,
        cost_per_unit,
        material_cost_rate,
        material_id,
        material_invoice_rate,
        name,
        unit_id,
        unit_name,
        driver_hourly_rate,
        commissions,
        material_broker,
        taxable,
        rate_owner,
        rate_sub,
        customer_hauling,
      } = job_billing;

      setValue('unit_id', [{ value: unit_id, label: unit_name }]);
      setValue('materials', [{ value: material_id, label: name }]);
      setValue('material_cost_rate', formatter.format(material_cost_rate));
      setValue('billperunit', formatter.format(cost_per_unit));
      setValue('bill_invoice_rate', formatter.format(bill_per_unit)); //este invertir
      setValue('driver_hourly_rate', formatter.format(driver_hourly_rate));
      setValue('material_invoice_rate', formatter.format(material_invoice_rate));
      setValue('material_broker', material_broker);
      setValue('commissions', commissions);
      setValue('taxable', taxable);
      setValue('rate_owner', formatter.format(rate_owner));
      setValue('rate_sub', formatter.format(rate_sub));
      setCost(material_broker);
      setComission(commissions);
      setTax(taxable);
      setValue('customer_hauling', customer_hauling == null ? true : customer_hauling);
      setCustomerHauling(customer_hauling == null ? true : customer_hauling);
    } else {
      setValue('material_broker', showCost);
      setValue('taxable', taxRate);
      setValue('subcontractor', []);
    }

  }, [jsonGetById]);


  function checkSubcontractor(subcontractor_comp_id, subcontractorArr) {
    for (var i = 0; i < subcontractorArr.length; i++) {
      if (subcontractorArr[i].user_id === subcontractor_comp_id) {
        return true;
      }
    }
    return false;
  }

  for (let i = 0; i < subs.length; i++) {
    inputFields.map((e) => {
      if (subs[i].value == e.subcontractor_comp_id) {
        e.company_id = subs[i].label;
      }
    });
  }

  for (let i = 0; i < ownerlist.length; i++) {
    inputFields.map((e) => {
      if (ownerlist[i].value == e.subcontractor_comp_id) {
        e.company_id = ownerlist[i].label;
      }
    });
  }

  useEffect(() => {
    if (inputFields.length > 0) {
      if (inputFields[0].subcontractor_comp_id == '' && dataSpecial.length > 0) {
        setInputFields([...dataSpecial]);
        setValue('subcontractor', dataSpecial);
      } else {
        setValue('subcontractor', inputFields);
      }
    } else {
      setIsEditSpecial(false);
    }
  }, [dataSpecial, inputFields]);

  const confirmDeletion = async () => {
    const values = [...inputFields];
    if (values.length == 0) {
      setInputFields([
        ...inputFields,
        {
          identifi: uuidv4(),
          subcontractor_comp_id: '',
          unit_rate: '',
          company_id: '',
          hourly_rate: 0,
          date_created: new Date(),
        },
      ]);

      return;
    }

    values.splice(
      values.findIndex((value) => value.identifi === currentIdentifi),
      1
    );
    setPositions(values.length);
    setInputFields(values);
    setValue('subcontractor', values);
    const new_delete = [...deletespecial];
    new_delete.push(currentIdentifi);
    setDeleteSpecial(new_delete);
    // Ocultar el modal después de la eliminación
    setShowModal(false);
  };

  const cancelDeletion = () => {
    // Ocultar el modal sin eliminar
    setShowModal(false);
  };

  const onCommissionMatrixSave = (matrix) => {
    console.log('matrix',matrix)

    try {
      ButtonLog({button_name:'commission_matrix_save',type:'job',info:`${JSON.stringify(matrix)}`})
    } catch (error) {
      console.log('BUTTON LOG ERROR CONTINUE'.error)
    }
    //should send retro or not retroactive_agent_commission if true they want to add all tickets if not only going forward
    let _matrix = {}
    
    if (selectedCommissionMatrix) {
      _matrix = {...selectedCommissionMatrix, ...matrix, changed: true}
    } else {
      _matrix = {...matrix, changed: true}
    }

    
    if (commissionMatrixIndex !== -1 && commissionMatrixIndex !== null) {
      const _newMatrix = [...commissionMatrix]
      _newMatrix[commissionMatrixIndex] = _matrix
      setCommissionMatrix([..._newMatrix])
    } else {
      setCommissionMatrix((prev) => ([...prev, _matrix]))
    }

    setCommissionMatrixChanged(true)
    setCommissionMatrixIndex(null)
  }

  const [deleteMatrix, setDeleteMatrix] = useState({})
  const [deleteMatrixIdx,setDeleteMatrixIdx] = useState(null)
  const [showAgentDeleteModal, setShowAgentDeleteModal] = useState(false)

  const handleDeleteCommissionDelete = (item=null,index=null,retro_delete) => {
    const item_to_delete = item || deleteMatrix
    const index_to_delete = index || deleteMatrixIdx

    //if retro_delete they want to delete all tickets from agent else keep them

    const _newMatrix = [...commissionMatrix]
    if (item_to_delete?.id) {
      _newMatrix[index_to_delete] = {...item_to_delete, deleted: true, retro_delete}
    } else {
      _newMatrix.splice(index_to_delete, 1)
    }
    console.log('new matrix',_newMatrix)
    setCommissionMatrix([..._newMatrix])
    setCommissionMatrixChanged(true)

    setDeleteMatrix({}); 
    setDeleteMatrixIdx(null) 
    setShowAgentDeleteModal(false)
  }

  return (
    <div className="jpb-updates">
      <Dialog
        maxWidth="xs"
        open={showModal}
        onClose={cancelDeletion}
      >
        <DialogContent>
          <div>
            <p>Are you sure you want to delete this item?</p>
            <DialogActions>
              <Button
                style={{
                  // color amarillo
                  backgroundColor: '#f1c232',
                  color: '#000',
                }}
                onClick={confirmDeletion}
              >
                Confirmar
              </Button>
              <Button
                style={{
                  backgroundColor: '#ff0000',
                  color: '#fff',
                }}
                onClick={cancelDeletion}
              >
                Cancelar
              </Button>
            </DialogActions>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth="lg"
        open={showAgentDeleteModal}
        onClose={() =>{setShowAgentDeleteModal(false); setDeleteMatrix({}); setDeleteMatrixIdx(null)}}
        fullwidth
      >
        <DialogContent className='flex flex-col justify-between'>
          <div className='flex px-16 pb-12 items-center align-center'>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.6132 6.9432C18.0893 4.35227 21.9105 4.35226 23.3866 6.9432L36.1735 29.3874C37.6081 31.9055 35.7408 35 32.7868 35H7.21306C4.25902 35 2.39176 31.9055 3.82637 29.3874L16.6132 6.9432ZM21.6665 28.3333C21.6665 29.2538 20.9203 30 19.9998 30C19.0793 30 18.3332 29.2538 18.3332 28.3333C18.3332 27.4129 19.0793 26.6667 19.9998 26.6667C20.9203 26.6667 21.6665 27.4129 21.6665 28.3333ZM21.2498 15C21.2498 14.3096 20.6902 13.75 19.9998 13.75C19.3095 13.75 18.7498 14.3096 18.7498 15V23.3333C18.7498 24.0237 19.3095 24.5833 19.9998 24.5833C20.6902 24.5833 21.2498 24.0237 21.2498 23.3333V15Z" fill="#FF1414"/>
            </svg>

            <Typography fontWeight={'bold'} color={'red'}>Do you want to remove all of the agent's commissions for this job, or just remove him from the job?</Typography>

          </div>
            
          <div className='flex flex-row w-full'>
            <div onClick={() => {handleDeleteCommissionDelete(null,null,true)}} className='flex pr-16' style={{cursor:'pointer'}}>
              <Card className="flex flex-col p-32" style={{height:'100%', width:'100%', background:'#edca33', borderRadius:5, boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)'}}>            
                <Typography fontWeight={'bold'} color={'black'}>Remove all the agent's commissions? </Typography>
              </Card>
            </div>
            <div onClick={() => {handleDeleteCommissionDelete(null,null,false)}}  style={{cursor:'pointer'}}>
            <Card className="flex flex-col p-32" style={{height:'100%', width:'100%', background:'#edca33', borderRadius:5, boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)'}}>
              <Typography fontWeight={'bold'} color={'black'}>Remove agent from recieving future commissions? </Typography>
              </Card>
            </div>
          </div>

          <div className='flex px-16 pt-12 items-center align-center'>
            <ReportProblemOutlinedIcon sx={{color: 'red', fontSize:'15px'}} />
            <Typography fontWeight={'bold'} color={'red'} >All changes are final</Typography>

          </div>
          
        </DialogContent>
      </Dialog>

      <ModalSpecialRate
        bill_invoice_rate={getValues('bill_invoice_rate') ? getValues('bill_invoice_rate')?.replace(/[^0-9.-]+/g, '') : 0}
        setIsOpenModalSpecial={setIsOpenModalSpecial}
        isOpenModalSpecial={isOpenModalSpecial}
        setDataSpecial={setDataSpecial}
        inputFields={inputFields}
        setInputFields={setInputFields}
      ></ModalSpecialRate>

      <ModalCreateUnits
        user_id={data ? data.id : user?.user_id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      ></ModalCreateUnits>

      <ModalCreateMaterials
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        getAllMaterials={getAllMaterials}
      ></ModalCreateMaterials>

      {agentModal && <AgentCommissionRateModal 
        agentsOptions={agentsOptions}
        open={agentModal} 
        edited={agentModalEdit}
        handleClose={() => {
          setAgentModal(false)
          setAgentModalEdit(false)
          setSelectedCommissionMatrix(null)
        }}
        onSave={onCommissionMatrixSave}
        selectedCommissionMatrix={selectedCommissionMatrix}
        billing_exists={jsonGetById[0]?.job_billing ? true : false}
      />}

      <CommissionMatrixHistoryModal 
        open={matrixModal}
        onClose={() => setMatrixModal(false)}
        jsonGetById={jsonGetById}
      />
      <div className='flex flex-row justify-start align-center items-center ml-4'>
        <Typography fontWeight={'bold'} fontSize={'16px'}>{jsonGetById[0]?.bol_type ? 'Auto Invoice Bill of Lading?' : ' Auto Invoice Job?' }</Typography>
        <Checkbox checked={autoInvoice} onChange={() => setAutoInvoice(!autoInvoice)}/>
      </div>
      
      <div className={(!hasPermission('job-billing.read') && hasPermission('job-billing.browse')) || jsonGetById[0]?.bol_type == true ? "cardoutter flex flex-wrap w-full justify-start pb-64": "cardoutter flex flex-wrap justify-between"}>
        
        <div className={(!hasPermission('job-billing.read') && hasPermission('job-billing.browse')) || jsonGetById[0]?.bol_type == true ? "cardinner mr-16" : "cardinner"}>
          <Typography
            variant="h6"
            gutterBottom
            component="h2"
          >
            { jsonGetById[0]?.bol_type ?  "Material" : "Material Rates"}
          </Typography>
          <h3>Select the material being hauled</h3>
          <div className="">
            <Controller
              name="materials"
              control={control}
              render={({ field: { onChange, value} }) => (
                <Autocomplete
                  style={
                    jsonGetById
                      ? {
                          paddingLeft: 20,
                        }
                      : {}
                  }
                  disabled={jsonGetById[0]?.bol_type == true ? true : jobtype == 'sub' ? true : false}
                  className="mt-8 mb-16"
                  multiple
                  freeSolo={value.length == 1 ? false : true}
                  getOptionDisabled={(options) => (value.length == 1 ? true : false)}
                  options={mate}
                  value={value}
                  onChange={(event, newValue) => {
                    setCreateMaterials(false);
                    newValue.map((r) => {
                      if (r.value == 0) {
                        setCreateMaterials(true);
                      }
                    });
                    onChange(newValue);
                  }}
                  fullWidth
                  //  inputValue={inputValue}
                  //  onInputChange={handleInputChange}
                  //  onChange={handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={mate}
                      disabled={jobtype == 'sub' ? true : false}
                      placeholder={"Select a material"}
                      label="Materials"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                  limitTags={1}
                />
              )}
            />

            {jsonGetById[0]?.bol_type == false && hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <div className="cardtoggle">
              <FormControl
                component="fieldset"
                fullWidth
              >
                <FormGroup
                  aria-label="position"
                  row
                >
                  <Grid
                    display={'flex'}
                    style={{
                      marginBottom: '10px',
                      alignItems: 'center',
                      justifyContentontent: 'center',
                      flexWrap: 'wrap',
                      gap: '10px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '2rem' }}>Are You Selling The Material?</span>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <IOSSwitch
                            disabled={jobtype === 'sub'}
                            checked={showCost}
                            onChange={handle}
                          />
                        }
                      />
                    </div>
                    <Grid>
                      <span>{showCost == true ? 'YES' : 'NO'}</span>
                    </Grid>
                  </Grid>
                </FormGroup>

                <FormGroup
                  aria-label="position"
                  row
                >
                  <Grid
                    display={'flex'}
                    style={{
                      marginBottom: '10px',
                      alignItems: 'center',
                      justifyContentontent: 'center',
                      flexWrap: 'wrap',
                      gap: '16px',
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginRight: '2rem' }}>Is It Taxable? </span>
                      <FormControlLabel
                        label=""
                        labelPlacement="start"
                        control={
                          <IOSSwitch
                            disabled={jobtype == 'sub' ? true : false}
                            checked={taxRate}
                            onChange={tax}
                          />
                        }
                      />
                    </div>
                    <Grid>
                      <span>{taxRate == true ? 'YES' : 'NO'}</span>
                    </Grid>
                  </Grid>
                </FormGroup>
              </FormControl>
            </div>}

            {jsonGetById[0]?.bol_type == false && showCost && hasPermission('job-billing.read') && hasPermission('job-billing.browse') ? (
              <Controller
                name="material_invoice_rate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={jobtype == 'sub' ? true : false}
                    className="mt-8 mb-16 mx-2 margin-x-0"
                    label="Material Invoice Rate"
                    id="material_invoice_rate"
                    variant="outlined"
                    // error={!!errors.material_invoice_rate}
                    helperText={
                      !!errors.material_invoice_rate
                        ? 'Enter the Material Invoice Rate' //TODO improved message
                        : ''
                    }
                    fullWidth
                    // type={'number'}
                  />
                )}
              />
            ) : (
              (jsonGetById.material_invoice_rate = '')
            )}

            {jsonGetById[0]?.bol_type == false && showCost && hasPermission('job-billing.read') && hasPermission('job-billing.browse') ? (
              <Controller
                name="material_cost_rate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={jobtype == 'sub' ? true : false}
                    className="mt-8 mb-16 mx-2 margin-x-0"
                    error={!!errors.material_cost_rate}
                    // required
                    helperText={
                      errors?.material_cost_rate
                        ? 'Enter a value less than Material Invoice Rate' //TODO REVIEW this text may need to be more expalantory.
                        : ''
                    }
                    label="Material Cost Rate"
                    id="material_cost_rate"
                    variant="outlined"
                    fullWidth
                    // type={'number'}
                  />
                )}
              />
            ) : (
              (jsonGetById.material_cost_rate = '')
            )}
          </div>
        </div>

        <div className={!hasPermission('job-billing.read') && hasPermission('job-billing.browse') ? "cardinner mr-16" : "cardinner"}>
          <Typography
            variant="h6"
            gutterBottom
            component="h2"
          >
            { jsonGetById[0]?.bol_type == false ? 'Hauling Rates' : 'Hauling' }
          </Typography>
          <h3>Select the Unit of Measure that will appear on your invoice.</h3>
          {/* TODO correction */}
          <div className="">
            <Controller
              name="unit_id"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  style={{
                    paddingLeft: 0,
                  }}
                  disabled={jsonGetById[0]?.bol_type == true ? true : jobtype == 'sub' ? true : false}
                  className="mt-8 mb-16"
                  multiple
                  freeSolo={value.length == 1 ? false : true}
                  options={units}
                  getOptionDisabled={(options) => (value.length == 1 ? true : false)}
                  value={value}
                  onChange={(event, newValue) => {
                    setCreateUnits(false);
                    newValue.map((r) => {
                      if (r.value == 0) {
                        setCreateUnits(true);
                      }
                      else
                      {
                        onChange(newValue);
                      }
                    });
                    if(!newValue.length){onChange(newValue);}
                    
                  }}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={jobtype == 'sub' ? true : false}
                      // id={units}
                      placeholder="Select"
                      label="Units"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // helperText={value.length == 0 ? 'Please select categorie for this materials' : ''}
                    />
                  )}
                  limitTags={1}
                />
              )}
            />

            { jsonGetById[0]?.bol_type == false && hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <Controller
              name="bill_invoice_rate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled={jobtype == 'sub' ? true : false}
                  className="mt-8 mb-16 mx-2 margin-x-0"
                  label="Unit Invoice Rate"
                  id="bill_invoice_rate"
                  variant="outlined"
                  fullWidth
                  // type={'number'}
                />
              )}
            />}

          { jsonGetById[0]?.bol_type == false && hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <div className="cardtoggle">
              <Grid
                display={'flex'}
                style={{
                  marginBottom: '10px',
                  alignItems: 'center',
                  justifyContentontent: 'center',
                  flexWrap: 'wrap',
                  gap: '10px',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '1rem' }}>
                    Do you pay In-House Drivers commission?{' '}
                  </span>
                  <FormControlLabel
                    label=""
                    labelPlacement="start"
                    control={
                      <IOSSwitch
                        checked={showComission}
                        onChange={comission}
                      />
                    }
                  />
                </div>
                <Grid>
                  <span>{showComission == true ? 'YES' : 'NO'}</span>
                </Grid>
              </Grid>
              {!showComission ? (
                <div className="flex sm:flex-row flex-col mx-6 margin-x-0">
                  <Controller
                    name="driver_hourly_rate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        className="mt-8 mb-16 mx-2 margin-x-0"
                        label="Driver Hourly Rate"
                        id="driver_hourly_rate"
                        variant="outlined"
                        fullWidth
                        // type={'number'}
                      />
                    )}
                  />
                </div>
              ) : (
                (jsonGetById.driver_hourly_rate = '')
              )}
              <h3>
                If you are paying hourly wages on this job, select “No”. (This Only Applies To
                In-house Drivers)
              </h3>
            </div>}

            {jsonGetById[0]?.bol_type == false && hasPermission('job-billing.read') && hasPermission('job-billing.browse') && showCost ? (
              <div className="cardtoggle">
                <Grid
                  display={'flex'}
                  style={{
                    marginBottom: '10px',
                    alignItems: 'center',
                    justifyContentontent: 'center',
                    flexWrap: 'wrap',
                    gap: '10px',
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '1rem' }}>
                      Are you charging your customer for hauling ?{' '}
                    </span>
                    <FormControlLabel
                      label=""
                      labelPlacement="start"
                      control={
                        <IOSSwitch
                          checked={customerHauling}
                          onChange={handleCustomerHauling}
                        />
                      }
                    />
                  </div>
                  <Grid>
                    <span>{customerHauling == true ? 'YES' : 'NO'}</span>
                  </Grid>
                </Grid>
              </div>
            ) : null}

            {jsonGetById[0]?.bol_type == false &&  hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <Controller
              name="billperunit"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  //disabled={!cpuchange ? false : true}
                  className="mt-8 mb-16 mx-2 margin-x-0"
                  id="billperunit"
                  label="Unit Cost Rate"
                  variant="outlined"
                  fullWidth
                  // type={'number'}
                />
              )}
            />}
          </div>
        </div>

        {jsonGetById[0]?.bol_type == false &&  hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <div className="cardinner">
          <Typography
            variant="h6"
            gutterBottom
            component="h2"
          >
            Owner Operator / Subcontractor Rates
          </Typography>
          <h3></h3>
          <div className="">
            <Controller
              name="rate_sub"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16 mx-2 margin-x-0"
                  label="Subcontractor Unit Cost Rate"
                  id="rate_sub"
                  variant="outlined"
                  fullWidth
                  // type={'number'}
                />
              )}
            />

            <Controller
              name="rate_owner"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  className="mt-8 mb-16 mx-2 margin-x-0"
                  id="rate_owner"
                  label="Owner Operator Unit Cost Rate"
                  variant="outlined"
                  fullWidth
                  // type={'number'}
                />
              )}
            />
            <div className="note-text flex">
              <div className="noteicon">
                <svg
                  width="5"
                  height="18"
                  viewBox="0 0 5 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.18785 7.08365V15.8678C4.18785 16.4163 3.97444 16.9149 3.63006 17.2764C3.28614 17.6375 2.81079 17.862 2.28826 17.862C1.76574 17.862 1.29084 17.638 0.94646 17.2764C0.602537 16.9149 0.388672 16.4163 0.388672 15.8678V7.08365C0.388672 6.53509 0.602079 6.03653 0.94646 5.67499C1.29084 5.31345 1.76574 5.08942 2.28826 5.08942C2.81079 5.08942 3.28568 5.31345 3.63006 5.67499C3.97399 6.03605 4.18785 6.53509 4.18785 7.08365Z"
                    fill="black"
                  />
                  <path
                    d="M2.28801 0.138458C2.82656 0.138458 3.31474 0.367785 3.66782 0.738458C4.0209 1.10913 4.23934 1.62115 4.23934 2.18702C4.23934 2.75288 4.0209 3.2649 3.66782 3.63557C3.31474 4.00625 2.82702 4.23557 2.28801 4.23557C1.749 4.23557 1.26128 4.00625 0.908194 3.63557C0.555113 3.2649 0.33667 2.75288 0.33667 2.18702C0.33667 1.62115 0.555113 1.10913 0.908194 0.738458C1.26128 0.367785 1.749 0.138458 2.28801 0.138458Z"
                    fill="black"
                  />
                </svg>
              </div>
              <p>
                If you need to set a different rate for an owner operator or subcontractor, simply
                enter it and it will take precedence over the job-specified rate for that particular
                driver.
              </p>
            </div>
          </div>
        </div>}
      </div>
      {jsonGetById[0]?.bol_type == false && !!agentsOptions?.length && <div className="operatoroutter" style={{padding: 0, marginTop: '32px'}}>
        <div className='flex items-center'>
          <h2 style={{marginBottom: 0, marginRight: '8px'}}>Sales Agent Commissions</h2>
          <InputAdornment position="start">
            <Tooltip title="Add Agent">
              <Fab
                color="primary"
                size="small"
                onClick={() => setAgentModal(true)}
              >
                <AddIcon />
              </Fab>
            </Tooltip>
          </InputAdornment>
          
          <InputAdornment position="start">
            <Tooltip title="Add Agent">
              <Fab
                color="primary"
                size="small"
                onClick={() => setMatrixModal(true)}
              >
                <HistoryIcon />
              </Fab>
            </Tooltip>
          </InputAdornment>
        </div>
      </div>}

      {jsonGetById[0]?.bol_type == false && !!agentsOptions?.length && 
        <div className="operator-tabel">
          <table>
            {commissionMatrix?.length > 0 ? (
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Commission Type</th>
                  <th>Commission Rate</th>
                </tr>
              </thead>
            ) : null}
            
            <tbody>
              {!!commissionMatrix.length && commissionMatrix.filter(i => !i?.deleted).map((item, index) => (
                <tr key={index}>
                  <td>{agentsOptions?.length ?  agentsOptions?.find((i) => i.value === item?.agent_id)?.label : ''}</td>
                  <td>
                    {item?.commission_type 
                      ? item.commission_type === 'fixed' ? 'Fixed' : item.commission_type === 'percentage' ?  'Percentage' : 'Rate x Unit Qty'
                      : '' 
                    }
                  </td>
                  <td>{item?.commission_type ? item?.commission_type === 'fixed' ? formatter?.format(item?.commission_amount) : item?.commission_type === 'percentage' ? `${item?.commission_amount}%` : formatter?.format(item?.commission_amount) : '0.00'}</td>
                  <td>
                    <Button
                      size="small"
                      // disabled={inputFields.length === 1}
                      onClick={() => {
                        setDeleteMatrix(item); setDeleteMatrixIdx(index) 
                        if(item?.id){setShowAgentDeleteModal(true); }
                        else{handleDeleteCommissionDelete(item,index,false)}

                        /* handleDeleteCommissionDelete(item, index) */
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.9286 24.6428V15C13.9286 14.4078 14.4084 13.9286 15 13.9286C15.5916 13.9286 16.0714 14.4078 16.0714 15V24.6428C16.0714 25.235 15.5916 25.7142 15 25.7142C14.4084 25.7142 13.9286 25.235 13.9286 24.6428ZM9.64284 25.7142C10.2344 25.7142 10.7142 25.235 10.7142 24.6428V15C10.7142 14.4078 10.2344 13.9286 9.64284 13.9286C9.05124 13.9286 8.57144 14.4078 8.57144 15V24.6428C8.57144 25.235 9.05124 25.7142 9.64284 25.7142ZM20.357 25.7142C20.9486 25.7142 21.4284 25.235 21.4284 24.6428V15C21.4284 14.4078 20.9486 13.9286 20.357 13.9286C19.7654 13.9286 19.2856 14.4078 19.2856 15V24.6428C19.2856 25.235 19.7654 25.7142 20.357 25.7142ZM29.9998 6.4284V9.6426C29.9998 10.8238 29.0388 11.7854 27.857 11.7854H26.9194V24.6426C26.9194 27.5964 24.516 29.9998 21.5622 29.9998H8.43764C5.48384 29.9998 3.08044 27.5964 3.08044 24.6426V11.7858H2.14304C0.961244 11.7858 0.000244141 10.8242 0.000244141 9.643V6.4288C0.000244141 5.2476 0.961244 4.286 2.14304 4.286H10.7144V3.2146C10.7142 1.4418 12.1562 0 13.9286 0H16.0714C17.8438 0 19.2856 1.4418 19.2856 3.2142V4.2856H27.857C29.0388 4.2856 29.9998 5.2472 29.9998 6.4284ZM12.857 4.2856H17.1428V3.2142C17.1428 2.623 16.662 2.1428 16.0714 2.1428H13.9286C13.338 2.1428 12.8572 2.623 12.8572 3.2142V4.2856H12.857ZM24.7766 11.7856H5.22324V24.6428C5.22324 26.4152 6.66504 27.857 8.43744 27.857H21.5624C23.3348 27.857 24.7766 26.4152 24.7766 24.6428V11.7858V11.7856ZM27.8586 9.6428L27.857 6.4286H2.14284V9.6428H27.8588H27.8586Z"
                          fill="black"
                        />
                      </svg>
                    </Button>
                  </td>
                  <td>
                    <Button
                      size="small"
                      onClick={() => {
                        setCommissionMatrixIndex(index)
                        setSelectedCommissionMatrix(item)
                        setAgentModal(true)
                        setAgentModalEdit(true)
                      }}
                    >
                      <Icon>edit</Icon>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
            
          </table>
        </div>
      }

      { jsonGetById[0]?.bol_type == false && hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <div className="operatoroutter" style={{paddingTop: '16px'}}>
        <h2>Owner Operator / Subcontractor Special Rates</h2>
        <p>
          Please enter your special rates for owner-operators, and subcontractors here. Doing so
          will update the job rates of each individual.
        </p>
        <div className="operator-tabel">
          <table>
            {inputFields.length > 0 ? (
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Special Rate</th>
                </tr>
              </thead>
            ) : null}
            <Controller
              className="owner-operators flex-wrap"
              name="subcontractor"
              control={control}
              render={({ field }) => (
                <>
                  {inputFields.map((inputField) => (
                    <tbody>
                      <tr>
                        <td>{inputField.company_id ? inputField.company_id : '-----'}</td>
                        <td>
                          {checkSubcontractor(inputField.subcontractor_comp_id, ownerOperator)
                            ? 'Owner'
                            : 'Subcontractor'}
                        </td>
                        <td>{formatter.format(inputField.unit_rate)}</td>
                        <td>
                          <Button
                            size="small"
                            // disabled={inputFields.length === 1}
                            onClick={() => handleRemoveFields(inputField.identifi)}
                          >
                            <svg
                              width="30"
                              height="30"
                              viewBox="0 0 30 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.9286 24.6428V15C13.9286 14.4078 14.4084 13.9286 15 13.9286C15.5916 13.9286 16.0714 14.4078 16.0714 15V24.6428C16.0714 25.235 15.5916 25.7142 15 25.7142C14.4084 25.7142 13.9286 25.235 13.9286 24.6428ZM9.64284 25.7142C10.2344 25.7142 10.7142 25.235 10.7142 24.6428V15C10.7142 14.4078 10.2344 13.9286 9.64284 13.9286C9.05124 13.9286 8.57144 14.4078 8.57144 15V24.6428C8.57144 25.235 9.05124 25.7142 9.64284 25.7142ZM20.357 25.7142C20.9486 25.7142 21.4284 25.235 21.4284 24.6428V15C21.4284 14.4078 20.9486 13.9286 20.357 13.9286C19.7654 13.9286 19.2856 14.4078 19.2856 15V24.6428C19.2856 25.235 19.7654 25.7142 20.357 25.7142ZM29.9998 6.4284V9.6426C29.9998 10.8238 29.0388 11.7854 27.857 11.7854H26.9194V24.6426C26.9194 27.5964 24.516 29.9998 21.5622 29.9998H8.43764C5.48384 29.9998 3.08044 27.5964 3.08044 24.6426V11.7858H2.14304C0.961244 11.7858 0.000244141 10.8242 0.000244141 9.643V6.4288C0.000244141 5.2476 0.961244 4.286 2.14304 4.286H10.7144V3.2146C10.7142 1.4418 12.1562 0 13.9286 0H16.0714C17.8438 0 19.2856 1.4418 19.2856 3.2142V4.2856H27.857C29.0388 4.2856 29.9998 5.2472 29.9998 6.4284ZM12.857 4.2856H17.1428V3.2142C17.1428 2.623 16.662 2.1428 16.0714 2.1428H13.9286C13.338 2.1428 12.8572 2.623 12.8572 3.2142V4.2856H12.857ZM24.7766 11.7856H5.22324V24.6428C5.22324 26.4152 6.66504 27.857 8.43744 27.857H21.5624C23.3348 27.857 24.7766 26.4152 24.7766 24.6428V11.7858V11.7856ZM27.8586 9.6428L27.857 6.4286H2.14284V9.6428H27.8588H27.8586Z"
                                fill="black"
                              />
                            </svg>
                          </Button>
                        </td>

                        <td>
                          <Button
                            size="small"
                            // disabled={inputFields.length === 1}
                            onClick={() => handleEditFields(inputField.identifi)}
                          >
                            <Icon>edit</Icon>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </>
              )}
            />
          </table>
        </div>
      </div>}

      { jsonGetById[0]?.bol_type == false && hasPermission('job-billing.read') && hasPermission('job-billing.browse') && <div className="flex justify-between flex-wrap items-center">
        <div className="addrates">
          {/* <div className="addrates-names flex">
            <h2>Add New Special Rates</h2>
          </div> */}

          <div className="flex sm:flex-row flex-col sm:mt-0 mt-16 mx-6 margin-x-0 owner-operator">
            {isEditSpecial ? (
              <>
                <FormControl
                  className="ownerselect"
                  fullWidth
                >
                  <FormLabel>Owner Operator / Subcontractor</FormLabel>
                  <Controller
                    name="subcontractor_comp_id"
                    id="subcontractor_comp_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        variant="outlined"
                        value={
                          inputFields[position] ? inputFields[position].subcontractor_comp_id : ''
                        }
                        onChange={(event) =>
                          handleChangeInput(
                            inputFields[position] ? inputFields[position].identifi : '',
                            event
                          )
                        }
                      >
                        <ListSubheader style={{ backgroundColor: '#edca33' }}>
                          <strong>Subcontractor</strong>
                        </ListSubheader>
                        {contra.map((field) => (
                          <MenuItem
                            value={field.user_id}
                            key={field.compacctnum}
                          >
                            {field.compname}
                          </MenuItem>
                        ))}

                        <ListSubheader style={{ backgroundColor: '#edca33' }}>
                          <strong>Owner Operator</strong>
                        </ListSubheader>
                        {ownerOperator.map((field) => (
                          <MenuItem
                            value={field.user_id}
                            key={field.compacctnum}
                          >
                            {field.compname}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>

                <TextField
                  className="owner-operators-value margin-x-0"
                  label="Rate"
                  name="unit_rate"
                  variant="outlined"
                  value={inputFields[position] ? inputFields[position].unit_rate : 0}
                  onChange={(event) =>
                    handleChangeInput(
                      inputFields[position] ? inputFields[position].identifi : '',
                      event
                    )
                  }
                  fullWidth
                  InputProps={{ shrink: true }}
                />
              </>
            ) : null}

            <div className="saveoutter flex">
              <div className="flex flex-row items-center justify-center addbtn">
                <div
                  className="mt-8 sm:mb-16  mx-2 margin-x-0"
                  style={{ alignSelf: 'center' }}
                >
                  <InputAdornment position="start">
                    <Tooltip title="Add Contractor">
                      <Fab
                        color="primary"
                        size="small"
                        onClick={() => handleOpenModal()}
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </InputAdornment>
                </div>
              </div>
              <h2>Add</h2>
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    user: state?.loadz_auth?.AuthReducer?.user,
  };
};

export default connect(mapStateToProps)(memo(CreateJobBilling));

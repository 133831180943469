import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import moment from 'moment';
import styles from './TicketStyle.module.css';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import { PDFDownloadLink } from '@react-pdf/renderer';
import store from '../../main/ID_store/store';
import { TestTicketPdf } from './TestTicketPdf';
import TicketEmailPopup from './TicketEmailPopup';
import { Dialog, DialogContent, Hidden, Icon } from '@mui/material';
import { constants } from '../../../constants';
import ErrorBoundary from '../ErrorBoundary';
import { map } from "lodash";
import useOpenImagePreview from 'app/hooks/useOpenImagePreview';

const style = {
  position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 500,
  // maxHeight: "770px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 4,
  p: 0,
};

const boderanimate = {
  padding: '20px 30px',
  paddingBottom: '0',
  marginTop: '20px',
};
const printpdf = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  position: 'absolute',
  right: '10px',
  top: '10px',
  alignItems: 'center',
  width: 'auto',
  padding: '10px',
  background: 'red',
  borderRadius: '10px',
  paddingTop: '7px',
};
const printpdfREd = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
  position: 'absolute',
  right: '10px',
  top: '10px',
  alignItems: 'center',
  width: 'auto',
  padding: '10px',
  background: '#e9c917',
  borderRadius: '10px',
  paddingTop: '7px',
};
const printTesting = {
  width: '20%',
};
const printblabal = {
  backgroundColor: 'green',
};
const cursorp = {
  cursor: 'pointer',
};
export default function TicketPopup(props) {
  const [logoImage, setLogoImage] = useState(props.logo);
  const [signatureImage, setSignatureImage] = useState(null);
  const [comp, setComp] = useState(null);
  const tkstart = [];
  const tkend = [];
  const [scaleImage, setScaleImage] = useState([]);
  const [signatureUrl, setSignatureUrl] = useState(null);
  const {handlePreview} = useOpenImagePreview()
  // const [dateStart, setdateStart] = useState([]);

  const isWaitEndTimeActive = () => {
    if (
      props &&
      props.data &&
      props.data.wait_time == true &&
      (props.data.tk_status == 0 || props.data.tk_status == 2)
    ) {
      return true;
    } else if (props && props.data && props.data.tk_status == 1) {
      return false;
    } else {
      return false;
    }
  };

  // console.log('propsprops => s', props);

  async function getImageBlobFromUrl(url) {
    try {
      const image = await axios
        .get(url, {
          responseType: 'blob',
        })
        .then((response) => window.URL.createObjectURL(new Blob([response.data])));
      return image;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  React.useEffect(async () => {
    let compName = store.getState().compname;
    if (compName) {
      setComp(compName);
    }
    if (props && props.logo && props.logo.fileLocation) {
      const image = await getImageBlobFromUrl(props.logo.fileLocation);
      // setLogoImage(image);
    }
  }, [props?.logo?.fileLocation]);

  const handleclose = () => {
    setSignatureUrl(null);
    setScaleImage([]);
    props.handleclose();
  };

  const componentRef = React.forwardRef();

  const [open, setOpen] = useState(false);
  const emailhandleOpen = () => setOpen(false);

  const getScaleImage = async () => {
    if (props?.data?.scale_ticket_image) {
      const key_arr = []
      for(const img of props?.data?.scale_ticket_image){
      const key = img
      key_arr.push(key.includes('http') ? key : `${constants.URLLOCAL}/aws/signed?image=${key}`);
      }
      setScaleImage(key_arr);
    } else {
      setScaleImage([]);
    }
  };
  const getSignatureImage = async () => {
    if (props?.data?.tk_receievedby_mediaurl) {
      const key = props?.data?.tk_receievedby_mediaurl;
      setSignatureUrl(key.includes('http') ? key : `${constants.URLLOCAL}/aws/signed?image=${key}`);
      return;
    }
    setSignatureUrl(null);
  };
  useEffect(async () => {
    console.log('props effect', props.open);
    if (!props.open) return;
    await getScaleImage();
    await getSignatureImage();
  }, [props?.data?.tk_receievedby_mediaurl, props?.data?.scale_ticket_image, props.open]);

  return (
    <ErrorBoundary>
      {props && props.data && props.data != null ? (
        <div>
          <Dialog
            onClose={handleclose}
            maxWidth={scaleImage.length ? 'md' : 'xs'}
            fullWidth={true}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            {...props}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
          >
            <DialogContent className="p-0">
              <Box
                sx={{ width: scaleImage.length ? '100%' : '100%' }}
                className={`p-0 m-0 ${isWaitEndTimeActive() ? 'bg-black' : ''}`}
              >
                
                <div
                  // className={` bg-black ${
                  //   isWaitEndTimeActive() ? "bg-red" : ""
                  // }`}
                  style={isWaitEndTimeActive() ? printpdf : printpdfREd}
                >
                  <div
                    className="flex text-white align-center jsutify-center items-center"
                    onClick={(e) => {e.stopPropagation(); props.getTicketAudit(props.data)}}
                    style={{cursor: 'pointer', marginRight: '15px'}}
                  >
                    <Icon fontSize='large' style={{ color: 'white' }}>info</Icon>
                  </div>

                  <ReactToPrint
                    // pageStyle={pageStyle}
                    trigger={() => (
                      <div style={cursorp}>
                        <img
                          width="20px"
                          height="20px"
                          src="/assets/images/icons/print-icon-white.svg"
                          style={{ marginRight: '15px' }}
                        />
                      </div>
                    )}
                    content={() => componentRef.current}
                  />
                  
                  {props.data && logoImage ? (
                    <PDFDownloadLink
                      document={
                        <TestTicketPdf
                          data={props.data}
                          isWaitEndTimeActive={isWaitEndTimeActive()}
                          logoImage={logoImage}
                          signatureImage={signatureImage}
                          newLoc={props.newLoc}
                        />
                      }
                      fileName={`${props.data.job_no}-${props.data.tk_no}-${comp ? comp : ''}.pdf`}
                    >
                      {' '}
                      
                      <div style={cursorp}>
                        <img
                          width="20px"
                          height="20px"
                          src="/assets/images/icons/pdf-icon-white.svg"
                          style={{ marginRight: '15px', marginTop: '0px' }}
                        />
                      </div>{' '}
                    </PDFDownloadLink>
                  ) : null}
                  <div
                    onClick={() => setOpen(true)}
                    style={cursorp}
                  >
                    <img
                      width="25px"
                      height="25px"
                      src="/assets/images/icons/email-icon-white.svg"
                      style={{ marginRight: '15px', marginTop: '0px' }}
                    />
                  </div>
                  <div
                    className="text-white"
                    onClick={handleclose}
                    style={cursorp}
                  >
                    <img
                      width="25px"
                      height="25px"
                      src="/assets/images/icons/icons8-close-white.svg"
                    />
                  </div>
                  
                  
                </div>

                <div
                  ref={componentRef}
                  className={` pdfprintstyle  ticket-no ${styles.animateDefult} ${
                    isWaitEndTimeActive() ? styles.boderanimated : null
                  }`}
                >
                  <div className="flex flex-col md:items-center  md:flex-row ">
                    <div
                      style={boderanimate}
                      className={`${scaleImage.length ? 'w-full md:w-1/2' : 'w-full'} `}
                    >
                      <div className="box-pop-details mt-16">
                        <div
                          className={`first-row flex flex-wrap px-20 py-10 pb-0 ${
                            isWaitEndTimeActive() ? 'bg-white' : ''
                          }`}
                          style={{
                            border: isWaitEndTimeActive()
                              ? '1px solid #f40000'
                              : '1px solid #e9c917',
                            boxShadow: '0 0 2px #e5c617',
                          }}
                        >
                          <div className="w-1/3 text-center py-8">
                            <div className="imgbox flex flex-wrap h-full mr-16 printsizelogo">
                              {props && props.logo ? (
                                <img
                                  alt="Logo"
                                  src={props.logo}
                                  style={{ height: '80px' }}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="w-1/3 text-left py-8 printsizefont">
                            <p className="ticket-date">
                              <strong>Ticket Date: </strong>{' '}
                            </p>
                            <p className="truck">
                              <strong>Truck #: </strong>
                            </p>
                            <p className="job">
                              <strong>Job: </strong>{' '}
                            </p>
                            <p className="delivery-t">
                              <strong className="text-red">Delivery Ticket #: </strong>{' '}
                            </p>
                          </div>
                          <div className="w-1/3 text-left py-8 printsizefont">
                            <p className="ticket-date">
                              <span>{moment(props.data.date_updated).format('MMM, DD YYYY')}</span>
                            </p>
                            <p className="truck">
                              <span>{props.data.t_no_truck}</span>
                            </p>
                            <p className="job">
                              <span> {props.data.job_no}</span>
                            </p>
                            <p className="delivery-t">
                              <span className="text-red">{props.data.ticket_no}</span>
                            </p>
                          </div>
                        </div>
                        {open ? (
                          <TicketEmailPopup
                            open={open}
                            emailhandleOpen={emailhandleOpen}
                            {...props}
                          />
                        ) : null}
                        <div className="first-row flex flex-wrap mt-5 mb-5 printsizeaddress">
                          <div
                            className={`text-center w-full p-0 mt-5 mb-0 ${
                              isWaitEndTimeActive() ? 'text-white' : ''
                            }`}
                            style={{ fontSize: '14px' }}
                          >
                            {isWaitEndTimeActive() ? (
                              <svg
                                version="1.0"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                viewBox="0 0 96.000000 96.000000"
                                preserveAspectRatio="xMidYMid meet"
                                style={{ display: 'inline-block' }}
                              >
                                <g
                                  transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                  fill="#ffffff"
                                  stroke="none"
                                >
                                  <path
                                    d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                    -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                    114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                    -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                  />
                                </g>
                              </svg>
                            ) : (
                              <>
                                {props && props.newLoc && props.newLoc.jobQryPit ? (
                                  <>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      viewBox="0 0 96.000000 96.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                      style={{ display: 'inline-block' }}
                                    >
                                      <g
                                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                        fill="#000000"
                                        stroke="none"
                                      >
                                        <path
                                          d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                        -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                        114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                        -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                        />
                                      </g>
                                    </svg>
                                    {props.data.compaddress},{props.data.compcity},
                                    {props.data.compstate},{props.data.compzip}
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className={`second-row flex flex-wrap mb-10 printsizeheading ${
                            isWaitEndTimeActive() ? 'bg-white' : ''
                          } `}
                          style={{
                            border: isWaitEndTimeActive()
                              ? '1px solid #f40000'
                              : '1px solid #e9c917',
                            boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                          }}
                        >
                          <div
                            className={`w-full text-center p-5 printsizeh ${
                              isWaitEndTimeActive() ? 'text-white' : ''
                            }`}
                            style={{
                              background: isWaitEndTimeActive() ? '#f40000' : '#edca33',
                              fontSize: '20px',
                            }}
                          >
                            Delivery Ticket
                          </div>
                          <div className="w-full flex flex-wrap p-16 pb-8 pt-8 printsizefont printsizedt">
                            <div
                              className="w-full flex flex-wrap mb-5"
                              style={{ borderBottom: '1px solid #000' }}
                            >
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <strong>Customer: </strong>{' '}
                                </p>
                              </div>
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <span>{props.data.customer_name}</span>
                                </p>
                              </div>
                            </div>
                            <div
                              className="w-full flex flex-wrap mb-5"
                              style={{ borderBottom: '1px solid #000' }}
                            >
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <strong>Quarry Name: </strong>{' '}
                                </p>
                              </div>
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <span>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      viewBox="0 0 96.000000 96.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                      style={{ display: 'inline-block' }}
                                    >
                                      <g
                                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                        fill="#000000"
                                        stroke="none"
                                      >
                                        <path
                                          d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                            -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                            114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                            -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                        />
                                      </g>
                                    </svg>{' '}
                                    <span
                                      className="text-blues"
                                      style={{ color: 'blue' }}
                                    >
                                      {props.newLoc && props.newLoc.jobQryPit
                                        ? props.newLoc.jobQryPit.l_name
                                        : null}
                                    </span>
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div
                              className="w-full flex flex-wrap mb-5"
                              style={{ borderBottom: '1px solid #000' }}
                            >
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <strong>Destination Name: </strong>{' '}
                                </p>
                              </div>
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <span>
                                    <svg
                                      version="1.0"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      viewBox="0 0 96.000000 96.000000"
                                      preserveAspectRatio="xMidYMid meet"
                                      style={{ display: 'inline-block' }}
                                    >
                                      <g
                                        transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                                        fill="#000000"
                                        stroke="none"
                                      >
                                        <path
                                          d="M386 864 c-70 -22 -149 -103 -170 -174 -22 -73 -20 -123 8 -202 30
                                                        -84 77 -169 157 -281 66 -93 89 -113 115 -99 24 14 127 156 177 245 114 204
                                                        114 331 3 443 -80 79 -178 102 -290 68z m143 -178 c67 -35 65 -143 -4 -174
                                                        -51 -23 -107 -7 -131 39 -47 91 44 182 135 135z"
                                        />
                                      </g>
                                    </svg>{' '}
                                    <span
                                      className="text-blues"
                                      style={{ color: 'blue' }}
                                    >
                                      {props && props.newLoc && props.newLoc.jobDesination
                                        ? props.newLoc.jobDesination.l_name
                                        : null}
                                    </span>
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div
                              className="w-full flex flex-wrap mb-5"
                              style={{ borderBottom: '1px solid #000' }}
                            >
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <strong>Driver Name: </strong>{' '}
                                </p>
                              </div>
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <span>
                                    {props.data.user_fname} {props.data.user_lname}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div
                              className="w-full flex flex-wrap mb-5"
                              style={{ borderBottom: '1px solid #000' }}
                            >
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <strong>Ticket Date: </strong>{' '}
                                </p>
                              </div>
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <span>
                                    {moment(props.data.date_updated).format(
                                      'MMM, DD YYYY hh:mm:ss a'
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {isWaitEndTimeActive() ? null : (
                              <>
                                {props.data.commissions == true ? (
                                  <>
                                    {props?.data?.duration_time ? (
                                      <div
                                        className="w-full flex flex-wrap mb-5"
                                        style={{ borderBottom: '1px solid #000' }}
                                      >
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">
                                            <strong>Wait Time Duration: </strong>{' '}
                                          </p>
                                        </div>
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">{props.data.duration_time}</p>
                                        </div>
                                      </div>
                                    ) : null}
                                    {props?.data && props?.data?.tk_start_end_duration ? (
                                      <div
                                        className="w-full flex flex-wrap mb-5"
                                        style={{ borderBottom: '1px solid #000' }}
                                      >
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">
                                            <strong>Ticket Duration: </strong>{' '}
                                          </p>
                                        </div>
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">{props.data.tk_start_end_duration}</p>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    {props?.data?.tk_start ? (
                                      <div
                                        className="w-full flex flex-wrap mb-5"
                                        style={{ borderBottom: '1px solid #000' }}
                                      >
                                        <>
                                          <div className="w-1/2 text-left p-0">
                                            <p className="py-8">
                                              <strong>Ticket Start: </strong>{' '}
                                            </p>
                                          </div>
                                          <div className="w-1/2 text-left p-0">
                                            <p className="py-8">
                                              {props.data.tk_start.forEach(function (
                                                element,
                                                indice
                                              ) {
                                                if (element.length > 10) {
                                                  tkstart.push(
                                                    moment(element).format('hh:mm:ss A'),
                                                    ' - '
                                                  );
                                                } else {
                                                  tkstart.push(
                                                    moment(element, 'HH:mm:ss').format('hh:mm:ss A')
                                                  );
                                                }
                                              })}
                                              {tkstart}
                                            </p>
                                          </div>
                                        </>
                                      </div>
                                    ) : null}
                                    {props?.data?.tk_end ? (
                                      <div
                                        className="w-full flex flex-wrap mb-5"
                                        style={{ borderBottom: '1px solid #000' }}
                                      >
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">
                                            <strong>Ticket End: </strong>{' '}
                                          </p>
                                        </div>
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">
                                            {props.data.tk_end.forEach(function (element, indice) {
                                              if (element.length > 10) {
                                                tkend.push(
                                                  moment(element).format('hh:mm:ss A'),
                                                  ' - '
                                                );
                                              } else {
                                                tkend.push(
                                                  moment(element, 'HH:mm:ss').format('hh:mm:ss A')
                                                );
                                              }
                                            })}
                                            {tkend}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      'null'
                                    )}
                                    {props?.data && props?.data?.tk_start_end_duration ? (
                                      <div
                                        className="w-full flex flex-wrap mb-10"
                                        style={{ borderBottom: '1px solid #000' }}
                                      >
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">
                                            <strong>Total Ticket Duration: </strong>{' '}
                                          </p>
                                        </div>
                                        <div className="w-1/2 text-left p-0">
                                          <p className="py-8">{props.data.tk_start_end_duration}</p>
                                        </div>
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}

                            <div
                              className="w-full flex flex-wrap mb-10"
                              style={{ borderBottom: '1px solid #000' }}
                            >
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  <strong>Status: </strong>{' '}
                                </p>
                              </div>
                              <div className="w-1/2 text-left p-0">
                                <p className="py-8">
                                  {(() => {
                                    if (props.data.tk_status == 0) {
                                      return <span>IN-progress</span>;
                                    } else if (props.data.tk_status == 1) {
                                      return <span>Completed</span>;
                                    } else if (props.data.tk_status == 2) {
                                      return <span>Delayed</span>;
                                    } else {
                                      return <span>N/A</span>;
                                    }
                                  })()}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`second-row flex flex-wrap mb-10 printsizetab ${
                            isWaitEndTimeActive() ? 'bg-white' : ''
                          }`}
                          style={{
                            border: isWaitEndTimeActive()
                              ? '1px solid #f40000'
                              : '1px solid #e9c917',
                            boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                          }}
                        >
                          <div className="w-full flex flex-wrap p-0 printsizetab2">
                            <table className="w-full">
                              <tbody className="w-full">
                                <tr
                                  className="w-full text-center py-10"
                                  style={{
                                    background: isWaitEndTimeActive() ? '#f40000' : '#edca33',
                                    fontSize: '16px',
                                  }}
                                >
                                  <th
                                    className={`py-10 ${isWaitEndTimeActive() ? 'text-white' : ''}`}
                                  >
                                    QTY
                                  </th>
                                  <th
                                    className={`py-10 ${isWaitEndTimeActive() ? 'text-white' : ''}`}
                                  >
                                    UNITS
                                  </th>
                                  {props && props.data.scale === true ? (
                                    <th
                                      className={`py-10 ${
                                        isWaitEndTimeActive() ? 'text-white' : ''
                                      }`}
                                    >
                                      Scale No
                                    </th>
                                  ) : null}
                                  <th
                                    className={`py-10 ${isWaitEndTimeActive() ? 'text-white' : ''}`}
                                  >
                                    MATERIAL
                                  </th>
                                </tr>

                                <tr className="w-full text-center py-10">
                                  <td className="py-10">
                                    <span
                                      className="py-3 px-16"
                                      style={{
                                        border: '1px solid #111',
                                        backgroundColor: '#f4f4f4',
                                        borderRadius: '5px',
                                      }}
                                    >
                                      {props.data.tk_qty}
                                    </span>
                                  </td>
                                  <td className="py-10">{props.data.unit_name}</td>
                                  {props && props.data.scale === true ? (
                                    <td className="py-10">{props.data.tk_scale_no}</td>
                                  ) : null}
                                  <td className="py-10">{props.data.name}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className={`second-row flex flex-wrap mb-10 p-5 text-center justify-center printsizesignature ${
                            isWaitEndTimeActive() ? 'bg-white' : ''
                          }`}
                          style={{
                            border: isWaitEndTimeActive()
                              ? '1px solid #f40000'
                              : '1px solid #e9c917',
                            boxShadow: 'rgb(0 0 1 / 11%) 0px 6px 10px',
                          }}
                        >
                          {signatureUrl ? (
                            <img
                              alt="Signature-tag"
                              src={signatureUrl}
                              style={{ height: '100px' }}
                            />
                          ) : (
                            <div
                              className="flex items-center"
                              style={{ height: '100px' }}
                            >
                              <p>No Image</p>
                            </div>
                          )}
                        </div>

                        <div
                          className="userframe"
                          style={{
                            background: isWaitEndTimeActive() ? '#f40000' : '#edca33',
                          }}
                        >
                          <div
                            className="userframe-inner"
                            style={{
                              color: isWaitEndTimeActive() ? 'white' : 'black',
                            }}
                          >
                            <span>Received By</span>
                            <span> {props.data.tk_receivedby}</span>
                          </div>
                        </div>

                        {isWaitEndTimeActive() ? (
                          <div className="second-row flex flex-wrap mb-0 p-0  text-center justify-center flex-col items-center printsizeendwt">
                            <div
                              className="flex flex-wrap justify-center flex-col py-20 w-3/4 rounded-t-3xl"
                              style={{ background: '#f40000' }}
                            >
                              <h3
                                className="text-white"
                                style={{ fontSize: '25px' }}
                              >
                                End Wait Time
                              </h3>
                              <div className="p-5 bg-white rounded-lg my-5"></div>
                            </div>
                          </div>
                        ) : null}
                        <div className="flex flex-row justify-center pb-8">
                          Completed By:{' '}
                          {props.data.ticket_owner_comp
                            ? props.data.ticket_owner_comp
                            : props.data.compname}
                        </div>
                      </div>
                      {scaleImage.length && (
                        <Hidden smUp>
                          <div className="flex justify-center m-8 grid grid-cols-2 gap-y-2 gap-x-4">
                          {
                            props?.data?.scale_ticket_image && props?.data?.scale_ticket_image &&
                            map(Array.isArray(props?.data?.scale_ticket_image) ? props?.data?.scale_ticket_image : [props?.data?.scale_ticket_image], (image, index) => {
                            return (
                            <div
                                className="cursor-pointer flex"
                                onClick={() =>
                                window.open(
                                    `${constants.URLLOCAL}/aws/signed?image=${image}`,
                                    '_blank'
                                )
                                }
                            >
                                <img
                                style={{ height: '80px',width:'80px' }}
                                src={`${constants.URLLOCAL}/aws/signed?image=${image}`}
                                alt="ticket scale image"
                                />
                            </div>

                            
                            )
                        
                          })
                          }
                          </div>
                        </Hidden>
                      )}
                    </div>
                    {scaleImage.length && (
                      <Hidden smDown>
                        <div className="w-1/2 flex justify-center m-8 grid grid-cols-2 gap-y-2 gap-x-4">
                          {
                        props?.data?.scale_ticket_image && props?.data?.scale_ticket_image &&
                        map(Array.isArray(props?.data?.scale_ticket_image) ? props?.data?.scale_ticket_image : [props?.data?.scale_ticket_image], (image, index) => {
                        return (
                        <div
                            className="cursor-pointer flex"
                            onClick={() =>
                              handlePreview(image)
                            }
                        >
                            <img
                            style={{ height: '100%',width:'100%' }}
                            src={`${constants.URLLOCAL}/aws/signed?image=${image}`}
                            alt="ticket scale image"
                            />
                        </div>

                        
                        )
                        
                          })
                          }
                          </div>
                      </Hidden>
                    )}
                  </div>
                </div>
              </Box>
            </DialogContent>
          </Dialog>
        </div>
      ) : null}
    </ErrorBoundary>
  );
}

import { lazy } from 'react';

const DriverRequestReleaseResponseConfig = {
  settings: {
    layout: {
      style: 'layout1',
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        mode: 'fullwidth',
      },
    },
  },
  routes: [
    {
      path: '/driver-request-release/:id',
      component: lazy(() => import('./DriverRequestReleaseResponse')),
    },
  ],
};

export default DriverRequestReleaseResponseConfig;

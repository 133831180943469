import { takeLatest, put, fork, all, call } from 'redux-saga/effects';
import actions from './actions'
import client from '../../../../../client';
import { getErrorAction, getSuccessAction } from "../utility";
import { forEach, map } from 'lodash';

export function* watchGetAllTicketJobs() {
  yield takeLatest(actions.GET_ALL_TICKET_JOBS, function* ({ payload, onError,onSuccess }) {
    try {
      const uri = `/jobs/ticket-status/${payload.data.id}/${payload.data.estatus}/${payload.data.compacctnum}`
      const result = yield call(() => client.get(uri, { params: payload.params }))
      console.log('getalltickets ::', result)
      yield put({
        type: getSuccessAction(actions.GET_ALL_TICKET_JOBS),
        payload: {
          jobs: Array.isArray(result.data.tickets) ? result.data.tickets : [],
          page: payload.params?.page || 1,
          totalTicket: result?.data?.totalTicket ? Number(result.data.totalTicket) : 0,
          hasNextPage: result?.data?.tickets?.length > 0 ? true : false
        }
      })

      if(onSuccess) {
        onSuccess(result)
      }

    } catch (error) {
      if (onError) onError(error)

      yield put({
        type: getErrorAction(actions.GET_ALL_TICKET_JOBS),
      })
    }
  })
}

export function watchClearAllTicketJobs() {
  put({
    type: actions.CLEAR_ALL_TICKET_JOBS,
  })
}


export function* watchSearchJobTicket() {
  yield takeLatest(actions.SEARCH_JOB_TICKET, function* ({payload}) {
    try {
      const uri =  `/jobs/ticket-search/${payload.id}/${payload.searchText}/${payload.accountNumber}/${payload.selectedTab}`
      const result = yield call(() => client.get(uri))

      yield put({
        type: getSuccessAction(actions.SEARCH_JOB_TICKET),
        payload: {
          jobs: Array.isArray(result.data.tickets) ? result.data.tickets : [],
          page: 1,
          hasNextPage: true
        }
      })

    } catch (error) {
      yield put({
        type: getErrorAction(actions.SEARCH_JOB_TICKET),
      })
    }
  })
}

export function* watchSearchJobTicketDate() {
  yield takeLatest(actions.SEARCH_JOB_TICKET_DATE, function* ({payload}) {
    try {
      const uri =  `/jobs/ticket-search-date/${payload.id}/${payload.start}/${payload.end}/${payload.accountNumber}/${payload.selectedTab}/${payload.column}`
      const result = yield call(() => client.get(uri))

      yield put({
        type: getSuccessAction(actions.SEARCH_JOB_TICKET_DATE),
        payload: {
          jobs: Array.isArray(result.data.tickets) ? result.data.tickets : [],
          page: 1,
          hasNextPage: true
        }
      })

    } catch (error) {
      yield put({
        type: getErrorAction(actions.SEARCH_JOB_TICKET_DATE),
      })
    }
  })
}

export function* watchTransferJobTickets() {
  yield takeLatest(actions.TRANSFER_JOB_TICKETS, function* ({payload, onSuccess, onError}) {
    try {
      const uri =  `/ticket/job`
      const result = yield call(() => client.post(uri, payload))
      
      yield put({
        type: getSuccessAction(actions.TRANSFER_JOB_TICKETS),
      })

      if(onSuccess) {
        onSuccess(result)
      }
    } catch (error) {
      yield put({
        type: getErrorAction(actions.TRANSFER_JOB_TICKETS),
      })
      
      if(onError) {
        onError(error)
      }
    }
  })
}

export function* watchGetTrucks() {
  yield takeLatest(actions.GET_TRUCKS, function* ({payload}) {
    
    try {
      const uri = '/truck/all'
      const result = yield call(() => client.post(uri, payload))
      yield put({
        type: getSuccessAction(actions.GET_TRUCKS),
        payload: {
          trucks: Array.isArray(result.data) ? result.data : [],
        }
      })

    } catch(error) {
      yield put({
        type: getErrorAction(actions.GET_TRUCKS),
      })
    }
  })
}

export function* watchGetDrivers() {
  yield takeLatest(actions.GET_DRIVERS, function* ({payload}) {
    
    try {
      const uri = '/props/get-drivers'
      const result = yield call(() => client.post(uri, payload))
      
      yield put({
        type: getSuccessAction(actions.GET_DRIVERS),
        payload: {
          drivers: Array.isArray(result.data.drivers) ? result.data.drivers : [],
        }
      })

    } catch(error) {
      yield put({
        type: getErrorAction(actions.GET_DRIVERS),
      })    
    }
  })
}
export function* watchDeleteJobTickets() {
  yield takeLatest(actions.DELETE_JOB_TICKETS, function* ({payload, onSuccess, onError}) {
    try {
      const uri = '/ticket/delete'
      const result = yield call(() => client.post(uri, payload))
      console.log('ticket deleted ::', result)  

        yield put({
          type: getSuccessAction(actions.DELETE_JOB_TICKETS),
        })

        if(onSuccess) {
          onSuccess(result)
        }
     



      
      
    } catch (error) {
      yield put({
        type: getErrorAction(actions.DELETE_JOB_TICKETS),
      })  

      if(onError) {
        onError(error)
      }
    }
  })
}

export function* watchEditJobTicket() {
  yield takeLatest(actions.EDIT_JOB_TICKET, function* ({payload, onSuccess, onError}) {
    try {
      console.log(payload)
      const uri = '/ticket/edit'
      const result = yield call(() => client.post(uri, payload))
      

      if (result.status === 200) {
        console.log('ticket edit ::', result)    
        if(onSuccess) {
          console.log('success ::', result)    
          onSuccess(result.data)
        }

        yield put({
          type: getSuccessAction(actions.EDIT_JOB_TICKET),
        })
      }
      
    } catch (error) {
      yield put({
        type: getErrorAction(actions.EDIT_JOB_TICKET),
      })  
      if(onError) {
        onError(error)
      }
    }
  })
}



export function* watchGetMetricas() {
  yield takeLatest(actions.GET_METRICS, function* ({ payload }) {
    try {
      const uri = `/jobs/ticket/metrics/${payload.id}/${payload.status}/${payload.compacctnum}`
      const result = yield call(() => client.get(uri))

      yield put({
        type: getSuccessAction(actions.GET_METRICS),
        payload: {
          metrics: result?.data ? result.data : null
        }
      })

    } catch (error) {
      yield put({
        type: getErrorAction(actions.GET_METRICS),
      })
    }
  })
}

export function* watchGetJob() {
  yield takeLatest(actions.GET_JOB, function* ({ payload }) {
    try {
      const uri = `/jobs/ticket/${payload.id}`
      const result = yield call(() => client.get(uri))
      console.log(result.data[0])
      yield put({
        type: getSuccessAction(actions.GET_JOB),
        payload: {
          job: Array.isArray(result.data) ? result.data[0] : null
        }
      })

    } catch (error) {
      yield put({
        type: getErrorAction(actions.GET_JOB),
      })
    }
  })
}

export function* watchGetJobOG() {
  yield takeLatest(actions.GET_JOB_OG, function* ({ payload, onError }) {
    try {

      const jobOG = yield call(() => client.post(`/jobs/sub-from-og`), payload)

      const result = yield call(() => client.get(`/jobs/ticket/${jobOG.data.job_id}`))

      yield put({
        type: getSuccessAction(actions.GET_JOB),
        payload: {
          job: Array.isArray(result.data) ? result.data[0] : null
        }
      })

    } catch (error) {
      
      if(onError) {
        onError(error)
      }

      yield put({
        type: getErrorAction(actions.GET_JOB),
      })
    }
  })
}

export function* watchGetDriversForOwnerOP() {
  yield takeLatest(actions.GET_DRIVER_FOR_OWNER_OP, function* ({ payload }) {
    try {

      const getDriverUri = '/props/get-drivers'
      if(!payload.isSub) {
        
        const ownerOP = yield call(() => client.post('/ownerop/get', {comp_acct: payload.compacctnum}))
        const compacctnum = map(ownerOP.data.ownerops, (item) => item.compacctnum)
        
        compacctnum.push(payload.compacctnum)
        const result = yield call(() => client.post(getDriverUri, compacctnum))
        
        yield put({
          type: getSuccessAction(actions.GET_DRIVERS),
          payload: {
            drivers: Array.isArray(result.data.drivers) ? result.data.drivers : [],
          }
        })

      } else if (payload.isSub && payload.subId) {

        const result = yield call(() => client.post(getDriverUri, {compacctnum: payload.compacctnum}))
        yield put({
          type: getSuccessAction(actions.GET_DRIVERS),
          payload: {
            drivers: Array.isArray(result.data.drivers) ? result.data.drivers : [],
          }
        })
      }
    } catch (error) {
      yield put({
        type: getErrorAction(actions.GET_DRIVERS),
      })
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(watchGetAllTicketJobs),
    fork(watchClearAllTicketJobs),
    fork(watchSearchJobTicket),
    fork(watchSearchJobTicketDate),
    fork(watchTransferJobTickets),
    fork(watchGetTrucks),
    fork(watchGetDrivers),
    fork(watchDeleteJobTickets),
    fork(watchEditJobTicket),
    fork(watchGetMetricas),
    fork(watchGetJob),
    fork(watchGetJobOG),
    fork(watchGetDriversForOwnerOP),
  ]);
}

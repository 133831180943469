import { getErrorAction, getSuccessAction } from "../utility";
const actions = {
  GET_JOBS: 'getJobs',
  SEARCH_JOBS: 'searchJobs',
  GET_PENDING_JOBS: 'getPendingJobs',
  GET_BOL_PENDING_COUNTER: 'getBolPendingCounter',
  
  getJobs: ({ page, filter,compacctnum}, onError) =>{
    const payload = { params:{ page }, data: { filter,compacctnum }}
    return {
      type: actions.GET_JOBS,
      payload, 
      onError
    }
  },
 
  searchJobs: ({page, text,search,compacctnum,type}, onError) => {
    const payload = { params:{ page }, data: { text,compacctnum,type },search:{search}}
    return {
      type: actions.SEARCH_JOBS,
      payload, 
      onError
    }
  },

  getPendingJobs: ({ compacctnum}, onError) =>{
    const payload = {compacctnum}
    return {
      type: actions.GET_PENDING_JOBS,
      payload, 
      onError
    }
  },

  getBolPendingCount: ({compacctnum}, onError) =>{
    const payload = { data: {compacctnum }}
    return {
      type: actions.GET_BOL_PENDING_COUNTER,
      payload, 
      onError
    }
  },

}

export default actions
import { useCallback } from 'react';
import client from 'client';
import {constants} from '../../constants'

const useOpenImagePreview = () => {
  const handlePreview = useCallback(async (image) => {
    try {
      const response = await client.get(`${constants.URLLOCAL}/aws/signed`, {
        params: { image, url: true },
      });
      const imageUrl = response?.data;
      if (imageUrl) {
        const newTab = window.open();
        newTab.document.write(`
          <html>
            <body style="margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; background-color:black;">
              <img src="${imageUrl}" style="max-width: 100%; max-height: 100%;" />
            </body>
          </html>
        `);
        newTab.document.title = "Image Preview";
      } else {
        console.error('No image URL received');
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
    }
  }, []);

  return { handlePreview };
};

export default useOpenImagePreview;
import { useEffect, useRef, useState } from "react";
import { useFormContext, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const QuarryPitMap = (props) => {
  const { query, customerQuery, handleMaps } = props;
  const mapRef = useRef(null);
  const methods = useFormContext();
  const { control, formState, setValue } = methods;
  const { errors } = formState;

  const [map, setMap] = useState(null);
  const marker = useRef(null);

  const checkLongName = (objData, id) => {
    if (
      objData &&
      objData.address_components &&
      objData.address_components.length &&
      objData.address_components[id] != undefined &&
      objData.address_components.length == id &&
      objData.address_components[id].long_name
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    try {
      const newMap = new window.google.maps.Map(mapRef.current, {
        zoom: 12,
        center: { lat: 30.2426319, lng: -97.7542349 }, // Initial center
      });
      setMap(newMap);
    } catch (error) {
      console.error("Error initializing Google Map:", error);
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (map) {
      try {
        // Add event listener for map click
        map.addListener('click', onMapClick);
      } catch (error) {
        console.error("Error adding map click listener:", error);
      }
    }
  }, [map]);

  const onMapClick = (event) => {
    try {
      const clickedLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      setValue('l_latitude', event.latLng.lat());
      setValue('l_longitude', event.latLng.lng());

      changeMarkerPosition(clickedLocation);
      map.panTo(clickedLocation);

      handleMaps({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    } catch (error) {
      console.error("Error handling map click:", error);
    }
  };

  const changeMarkerPosition = (location) => {
    try {
      if (marker.current) marker.current.setMap(null);
      marker.current = new window.google.maps.Marker({
        position: location,
        map: map,
      });
    } catch (error) {
      console.error("Error changing marker position:", error);
    }
  };

  useEffect(() => {
    try {
      if (query && query?.l_latitude && query?.l_longitude) {
        const position = { lat: Number(query?.l_latitude), lng: Number(query?.l_longitude) };
        changeMarkerPosition(position);
      } else if (customerQuery && customerQuery?.l_latitude && customerQuery?.l_longitude) {
        const position = { lat: Number(customerQuery?.l_latitude), lng: Number(customerQuery?.l_longitude) };
        changeMarkerPosition(position);
      }
    } catch (error) {
      console.error("Error handling query/customerQuery changes:", error);
    }
  }, [query, customerQuery]);

  const search = () => {
    try {
      const input = document.getElementById('autocomplete-input');
      const autocomplete = new window.google.maps.places.Autocomplete(input);

      autocomplete.addListener('place_changed', () => {
        try {
          const place = autocomplete.getPlace();

          if (!place.geometry || !map) return;

          changeMarkerPosition(place.geometry.location);

          // Pan map to center around marker
          map.panTo(place.geometry.location);

          const dir = place.formatted_address;
          setValue('l_address', dir);
          const cit = checkLongName(place, 2) ? place.address_components[2].long_name : null;
          setValue('l_city', cit);
          const stat = checkLongName(place, 4) ? place.address_components[4].long_name : null;
          setValue('l_state', null ? null : stat);
          const zip = checkLongName(place, 6) ? place.address_components[6].long_name : null;
          setValue('l_zip', null ? null : zip);
          setValue('l_latitude', place.geometry.location.lat());
          setValue('l_longitude', place.geometry.location.lng());
          handleMaps({ latLng: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }, dir, cit, stat, zip });
        } catch (error) {
          console.error("Error handling place changed:", error);
        }
      });
    } catch (error) {
      console.error("Error initializing autocomplete:", error);
    }
  };

  return (
    <div className="w-500 h-512">
      <Controller
        name="l_address"
        control={control}
        render={({ field }) => (
          <TextField
            id="autocomplete-input"
            label="Search Address or Coordinates (latitude , longitude)"
            variant="outlined"
            fullWidth
            onChange={search}
          />
        )}
      />
      <div ref={mapRef} style={{ width: '550px', height: '500px', margin: '16px 0' }} />
    </div>
  );
};

export default QuarryPitMap;
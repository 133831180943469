import { getErrorAction, getSuccessAction } from "../utility";
const actions = {
  GET_ALL_TICKET_JOBS: 'getAllTicketJob',
  CLEAR_TICKET_JOBS: 'clearTicketJobs',
  TRANSFER_JOB_TICKETS: 'transferJobTickets',
  SEARCH_JOB_TICKET: 'searchJobTicket',
  SEARCH_JOB_TICKET_DATE: 'searchJobTicketDate',
  GET_DRIVERS: 'getDriver',
  GET_TRUCKS: 'getTrucks',
  EDIT_JOB_TICKET: 'editJobTicket',
  DELETE_JOB_TICKETS: 'deleteJobTickets',
  GET_METRICS: 'getMetrics',
  GET_JOB: 'getJob',
  GET_JOB_OG: 'getJobOG',
  GET_DRIVER_FOR_OWNER_OP: 'getDriversForOwnerOP',
  CLEAR_ALL_TICKET_JOBS: 'clearAllTicketJobs',

  getAllTicketJob: ({ page, data }, onError) => {
    const payload = { params: { page }, data }
    console.log('getAllTicketJob acrion ::', payload)
    return {
      type: actions.GET_ALL_TICKET_JOBS,
      payload,
      onError
    }
  },

  clearAllTicketJobs: () => {
    return {
      type: actions.CLEAR_ALL_TICKET_JOBS,
    }
  },

  searchJobTicket: (payload, onError) =>{
    console.log('searchJobTicket action ::', payload)
    return {
      type: actions.SEARCH_JOB_TICKET,
      payload,
      onError
    }
  },

  searchJobTicketDate: (payload, onError) =>{
    console.log('searchJobTicketDate action ::', payload)
    return {
      type: actions.SEARCH_JOB_TICKET_DATE,
      payload,
      onError
    }
  },

  transferJobTickets: (payload, onSuccess, onError) => {
    return {
      type: actions.TRANSFER_JOB_TICKETS,
      payload,
      onSuccess,
      onError
    }
  },

  getTrucks: (payload, onError) => {
    return {
      type: actions.GET_TRUCKS,
      payload,
      onError
    }
  },

  getDrivers: (payload, onError) => {
    return {
      type: actions.GET_DRIVERS,
      payload, 
      onError
    }
  },

  deleteJobTickets: (payload, onSuccess, onError) => {
    return {
      type: actions.DELETE_JOB_TICKETS,
      payload,
      onSuccess,
      onError
    }
  },

  editJobTicket: (data, onSuccess, onError) => {
    return {
      type: actions.EDIT_JOB_TICKET,
      payload: data,
      onSuccess,
      onError
    }
  },

  getMetrics: (payload, onError) => {
    return {
      type: actions.GET_METRICS,
      payload,
      onError
    }
  },

  getJob: (payload, onError) => {
    return {
      type: actions.GET_JOB,
      payload,
      onError
    }
  },

  clearTicketJobs: () => {
    return {
      type: actions.CLEAR_TICKET_JOBS,
      payload: {}
    }
  },

  getJobFromOG: (payload, onError) => {
    return {
      type: actions.GET_JOB_OG,
      payload,
      onError
    }
  }, 

  getDriverForAddTicket: (payload) => {
    return {
      type: actions.GET_DRIVER_FOR_OWNER_OP,
      payload
    }
  }

}

export default actions

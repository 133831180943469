import React, {useState,useEffect} from 'react';
import {Typography,Tooltip, TextField } from '@mui/material';
import { Icon, IconButton} from '@mui/material';


const ImageAnnotationsTextEdit = ({annotation,idx,handleSave}) => {

    const [text,setText] = useState(annotation.data.text);
    const [startEdit,setStartEdit] = useState(false);

    const handleSaveEdit = () => {
        setStartEdit(false);
        handleSave(annotation.data.annote_id,idx,text);
    }

    const onClose = () => {
        setStartEdit(false);
    }

    useEffect(() => {
        if(startEdit == true){setText(annotation.data.text)}
    },[startEdit]) 



    return(
       <>

            {
                startEdit ? 

                <>
                    <TextField
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Enter text"
                        multiline={true}
                        fullWidth={true}
                    />
                    
                    <div className='flex flex-col items-center align-center'>

                        <Tooltip title="Save" placement='right'>
                            <IconButton onClick={() => handleSaveEdit(annotation.data.annote_id,idx,text)}>
                                <Icon>
                                    save
                                </Icon>
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Cancel" placement='right'>
                            <IconButton onClick={() => onClose()}>
                                <Icon>
                                    cancel
                                </Icon>
                            </IconButton>
                        </Tooltip>

                    </div>
                
                </>
                :
                <div className='flex flex-row items-center justify-between w-full'>
                    <div
                        style={{
                            overflowX: 'auto',
                            marginRight: '64px',
                            wordWrap: 'break-word', // Ensure text wraps within the width
                            whiteSpace: 'normal', // Allow text to wrap
                        }}
                    >
                        <Typography>{annotation.data.text}</Typography>
                    </div>
                    
                    <div>
                        <Tooltip title='edit' placement='right'>
                            <IconButton sx={{padding:0}} onClick={() => setStartEdit(true)}>
                                <Icon style={{ fontSize: '16px' }}>edit</Icon>
                            </IconButton>
                        </Tooltip>
                    </div>
                    
                </div>
            }
       
       </>
    )
}






export default ImageAnnotationsTextEdit;
import React, { useEffect, useState } from 'react';
import { constants } from '../../../../../../constants';
import client from 'client';

const FilePreview = ({ imageKey }) => {
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const getUrl = async () => {
    try {
      const params = { image: imageKey, url: true, contentType: 'pdf' };

      if (!imageKey.includes('pdf')) return;
      setLoading(true);
      const response = await client.get(`${constants.URLLOCAL}/aws/signed`, {
        params,
      });

      const imageUrl = response?.data;
      if (imageUrl) {
        setUrl(imageUrl);
      }
    } catch (error) {
      console.error('Error fetching signed URL:', error);
    } finally {
      setLoading(false); // Stop loading when done
    }
  };

  useEffect(() => {
    if (!imageKey) return;
    setLoading(true);
    getUrl();
  }, [imageKey]);

  if (!imageKey) {
    return <p>No file to preview</p>;
  }

  const containerStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  };

  const iframeStyle = {
    width: '100%',
    border: 'none',
    overflow: 'hidden', // Disable scrolling
    border: 'none',
    pointerEvents: 'none'
  };

  const renderPreview = () => {
    if (loading) {
      return <p>Loading preview...</p>;
    }

    if (imageKey) {
      if (imageKey.includes('pdf')) {
        return (
          <div style={iframeStyle}>
            <iframe
              src={url}
              title="PDF Preview"
            />
          </div>
        );
      } else {
        return (
          <img
            src={`${constants.URLLOCAL}/aws/signed?image=${imageKey}`}
            alt="Image preview"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain',
            }}
          />
        );
      }
    }

    return <p>Unable to determine file type</p>;
  };

  return (
    <div
      className="flex flex-col justify-center align-center items-center relative"
      style={containerStyle}
    >
      {renderPreview()}
    </div>
  );
};

export default FilePreview;
